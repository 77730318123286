<template>
  <div class="container">
    <platform-header
      title="线索管理"
      :currentTab="currentTab"
      @tabChange="tabChange"
      style="padding-top: 27px; padding-bottom: 20px"
    />
    <div class="tools">
      <a-input-search
        v-model.trim="form.dealer"
        class="aft-search"
        placeholder="搜索门店名称或编码"
        @search="getTableData(true)"
        @pressEnter="getTableData(true)"
        allowClear
      />
    </div>
    <div class="tools">
      <div>
        <a-select
          class="aft-select default"
          placeholder="请选择一级大区"
          style="width: 160px; margin-right: 8px"
          v-model="form.region_id"
          @change="regionChange"
          allowClear
        >
          <a-select-option
            v-for="item in regionList || []"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-if="form.region_id !== undefined && form.region_id !== ''"
          class="aft-select default"
          placeholder="请选择二级大区"
          style="width: 160px; margin-right: 8px"
          v-model="form.village_id"
          @change="getTableData(true)"
          allowClear
        >
          <a-select-option
            v-for="item in villageList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
          style="width: 160px; height: 40px"
          v-model="form.group_code"
          :suffixIcon="suffixIcon"
          placeholder="选择分组"
          @change="getTableData(true)"
        >
          <a-select-option :value="''">不限分组</a-select-option>
          <a-select-option v-for="d in groupList" :key="d.group_code">
            {{ d.group_name }}
          </a-select-option>
        </a-select>
      </div>
      <date-tab-all
        :type="dateType"
        ref="dateTab"
        @changeType="changeType"
        @changeDate="changeDate"
      ></date-tab-all>
    </div>
    <div class="tools">
      <ButtonGroup
        :tab-data="btnTabsList[currentTab]"
        :defaultValue="listTab"
        ref="ButtonGroup"
        @tabChange="handleBtnTabsChange"
      />
      <a-button class="button2" @click="exportExcel">
        <svg-icon icon-class="export" class="btn-icon" />
        <span>导出表格</span>
      </a-button>
    </div>

    <div class="sum-wrapper" v-if="countData">
      <div class="sum-item all">
        <span class="sum-name"> 线索总量 </span>
        <span class="sum-value">
          {{ countData.total | changeNum }}
        </span>
      </div>
      <div class="line"></div>
      <div class="sum-item" v-for="(item, index) in sumColumns" :key="index">
        <span class="sum-name">
          {{ item.label }}
        </span>
        <span class="sum-value">
          {{ countData[item.value] | changeNum }}
        </span>
      </div>
    </div>

    <div class="content">
      <div class="table">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :rowKey="(data) => data.dealer_id"
          :pagination="false"
          :loading="tableLoading"
          :scroll="{
            x: '100%',
            y: 'calc(100vh - 580px)',
          }"
          :locale="locale"
          class="aft-table default x-scroll"
        >
          <div
            slot="num"
            slot-scope="text, records, index"
            class="font-color2 f14"
          >
            <span> {{ (current - 1) * pageSize + Number(index) + 1 }}</span>
          </div>
          <div slot="account" slot-scope="text, record" class="table-account">
            <div class="table-account-left" @click="openDetail(record)">
              <WebpImage
                :src="record.avatar"
                :width="'48px'"
                :height="'48px'"
                :radius="'50%'"
                class="img"
              />
              <div class="table-account-content">
                <div class="dealer-name">
                  {{ record.dealer_name }}
                </div>
                <div class="store-info">
                  <span>
                    {{ record.region_name
                    }}<template v-if="record.village_name">-</template
                    >{{ record.village_name }}
                  </span>
                  <a-divider type="vertical" />
                  <span>门店编码：{{ record.dealer_store_code }}</span>
                </div>
                <div class="company-name">
                  {{ record.dealer_company_name }}
                </div>
                <div>
                  <a-tag
                    color="#27C34633"
                    style="color: #24bf79ff"
                    v-if="record.p2_oauth_status === 1"
                  >
                    互动数据 · 已授权
                    <a-icon type="check-circle" theme="filled" />
                  </a-tag>
                  <a-tooltip
                    title="开放平台授权未成功时，影响互动数据线索抓取"
                    v-if="record.p2_oauth_status === 0"
                  >
                    <a-tag color="rgba(255,158,61,0.2)" style="color: #ff9026">
                      互动数据 · 未授权
                      <a-icon type="exclamation-circle" theme="filled" />
                    </a-tag>
                  </a-tooltip>
                  <a-tooltip
                    title="开放平台授权未成功时，影响互动数据线索抓取"
                    v-if="record.p2_oauth_status === 2"
                  >
                    <a-tag color="rgba(255,158,61,0.2)" style="color: #ff9026">
                      互动数据 · 部分未授权
                      <a-icon type="exclamation-circle" theme="filled" />
                    </a-tag>
                  </a-tooltip>

                  <a-tag
                    color="#27C34633"
                    style="color: #24bf79ff"
                    v-if="record.p6_oauth_status === 1"
                  >
                    高级数据 · 已授权
                    <a-icon type="check-circle" theme="filled" />
                  </a-tag>
                  <a-tooltip
                    title="高级数据授权影响直播、视频、个人主页等企业号后台的线索抓取，如果您的集团已开通车云店，可忽略此信息"
                    v-else-if="record.p6_oauth_status === 0"
                  >
                    <a-tag color="rgba(255,158,61,0.2)" style="color: #ff9026">
                      高级数据 · 未授权
                      <a-icon type="exclamation-circle" theme="filled" />
                    </a-tag>
                  </a-tooltip>
                  <a-tooltip
                    title="高级数据授权影响直播、视频、个人主页等企业号后台的线索抓取，如果您的集团已开通车云店，可忽略此信息"
                    v-else-if="record.p6_oauth_status === 2"
                  >
                    <a-tag color="rgba(255,158,61,0.2)" style="color: #ff9026">
                      高级数据 · 部分未授权
                      <a-icon type="exclamation-circle" theme="filled" />
                    </a-tag>
                  </a-tooltip>
                  <a-tooltip
                    title="高级数据授权影响直播、视频、个人主页等企业号后台的线索抓取，如果您的集团已开通车云店，可忽略此信息"
                    v-else-if="record.p6_oauth_status === 3"
                  >
                    <a-tag color="rgba(255,158,61,0.2)" style="color: #ff9026">
                      高级数据 · 非蓝v无法授权
                      <a-icon type="exclamation-circle" theme="filled" />
                    </a-tag>
                  </a-tooltip>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div
            slot="dealer_store_code"
            slot-scope="text"
            class="font-color3 f14"
          >
            <span>{{ text || "-" }}</span>
          </div>
          <div slot="region" slot-scope="text" class="font-color3 f14">
            <span>{{ text || "-" }}</span>
          </div>

          <div
            slot="action"
            slot-scope="scope"
            class="font-color3 f14 action-box"
          >
            <a-icon
              type="star"
              class="font-color6 f20"
              theme="filled"
              @click.stop="starClick(scope.dealer_id)"
              v-if="scope.favorite == 1"
            />
            <a-icon
              type="star"
              class="font-color3 f20"
              @click.stop="starClick(scope.dealer_id)"
              v-else
            />
          </div>
          <div
            slot="action2"
            slot-scope="scope"
            class="font-color3 f14 action-box"
          >
            <span class="action-text" @click.stop="openDetailModal(scope)">
              查看详情
            </span>
          </div>
        </a-table>
      </div>
    </div>

    <div class="page-div aft-pagination">
      <a-pagination
        v-model="current"
        size="small"
        :pageSize="pageSize"
        :total="tablePagination.total"
        @change="pageChange"
      />
    </div>

    <LineModal ref="LineModal" />
    <PieModal ref="PieModal" />
  </div>
</template>

<script>
import * as vuex from "vuex";
const { mapState, mapMutations, mapActions } =
  vuex.createNamespacedHelpers("closedLoop");
import PlatformHeader from "@/components/PlatformTab/PlatformHeader";
import DateTabAll from "@/components/DateTab/DateTabAll";
import ButtonGroup from "@/components/PlatformTab/ButtonGroup";
import WebpImage from "@/components/WebpImage/WebpImage";
import {
  columns1_1,
  columns1_2,
  columns1_3,
  sum1,
  sum2,
  sum3,
} from "./options/constant";
import deepClone from "@/utils/clone-utils";
import PieModal from "./components/PieModal";
import LineModal from "./components/LineModal";
import Empty from "@/components/Empty/Empty";
import exportTable from "@/utils/exportTable";

const DEFAULT_LIST_TAB = "1";
export default {
  components: {
    PlatformHeader,
    DateTabAll,
    ButtonGroup,
    WebpImage,
    PieModal,
    LineModal,
    Empty,
  },
  data() {
    return {
      dateType: "month",
      currentTab: "douyin",
      btnTabsList: {
        douyin: [
          {
            label: "按收集渠道",
            value: "1",
          },
          {
            label: "按意向车系",
            value: "2",
          },
          {
            label: "按本地异地",
            value: "3",
          },
        ],
        kuaishou: [
          {
            label: "按收集渠道",
            value: "1",
          },
        ],
      },
      listTab: DEFAULT_LIST_TAB,
      // 是否官号线索
      isOpenOfficialClue: false,

      sumColumns: [],
      columns: [],
      current: 1,
      pageSize: 20,

      form: {
        start_time: undefined,
        end_time: undefined,
        group_code: undefined,
        region_id: undefined,
        village_id: undefined,
        dealer: undefined,
      },

      locale: {
        emptyText: (
          <Empty
            style="margin-top: 80px;margin-bottom:80px;background:transparent"
            title="暂无数据"
          />
        ),
      },
      suffixIcon: (
        <svg-icon icon-class="select-arrow" style="font-size: 12px;" />
      ),
    };
  },
  computed: {
    ...mapState({
      activityList: (state) => state.activityList,
      regionList: (state) => state.regionList, // 区域列表
      tableData: (state) => state.tableData,
      countData: (state) => state.countData,
      groupList: (state) => state.groupList,
      tableLoading: (state) => state.tableLoading,
      tablePagination: (state) => state.tablePagination,
      multipleData: (state) => state.multipleData,
      chartData: (state) => state.chartData,
      saleClueCountSum: (state) => state.saleClueCountSum,
    }),
    villageList() {
      return (
        this.regionList.find((item) => item.id === this.form.region_id)
          ?.subList || []
      );
    },
  },
  methods: {
    ...mapActions([
      "getActivityList",
      "getRegionList",
      "getClosedLoopList",
      "getSaleClueListByCollect",
      "getSaleClueListByFlow",
      "getSaleClueCountByFlow",
      "getSaleClueListByArea",
      "getSaleClueCountByArea",
      "getSaleClueListBySeries",
      "getSaleClueCountBySeries",
      "getSortGroup",
      "getDealerGroup",
      "getClosedLoopChart",
      "getSaleClueCountSum",
    ]),
    ...mapMutations(["changeState"]),
    setTableColumns() {
      // 按收集渠道
      if (this.listTab === "1") {
        this.columns = deepClone(columns1_1);
        this.sumColumns = deepClone(sum1);
      } else if (this.listTab === "2") {
        // 按意向车系
        this.columns = deepClone(columns1_2);
        this.sumColumns = deepClone(sum2);
      } else if (this.listTab === "3") {
        // 按本地异地
        this.columns = deepClone(columns1_3);
        this.sumColumns = deepClone(sum3);
      }
    },
    regionChange() {
      this.form.village_id = undefined;
      this.getTableData(true);
    },
    // 月份/周 切换
    changeType(type) {
      this.dateType = type;
    },
    changeDate(data) {
      this.form.start_time = data.startTime;
      this.form.end_time = data.endTime;
      this.getTableData(true);
    },
    // 获取表格数据
    getTableData(bool = true) {
      const platform = this.currentTab;
      const params = {
        ...this.form,
        current: this.current,
        page_size: this.pageSize,
      };
      if (this.listTab === "1") {
        this.getSaleClueListByFlow({ params, platform });
        bool && this.getSaleClueCountByFlow({ params, platform });
      } else if (this.listTab == "2") {
        this.getSaleClueListBySeries({ params, platform });
        bool && this.getSaleClueCountBySeries({ params, platform });
      } else if (this.listTab === "3") {
        this.getSaleClueListByArea({ params, platform });
        bool && this.getSaleClueCountByArea({ params, platform });
      }
    },
    handleBtnTabsChange(val) {
      this.listTab = val || DEFAULT_LIST_TAB;
      this.setTableColumns();
      this.current = 1;
      this.getTableData(true);
    },
    async tabChange(val) {
      this.current = 1;
      this.currentTab = val;
      this.activity_id = undefined;
      this.$nextTick(async () => {
        this.handleBtnTabsChange(DEFAULT_LIST_TAB);
        await this.getActivityList({
          platform: this.currentTab,
        });
        this.getTableData("sum");
      });
    },
    // 页码切换
    pageChange() {
      this.getTableData();
    },
    openDetail(record) {
      window.open(
        `/dealer/dealerDetail?id=${record.dealer_id}&platform=${this.currentTab}`
      );
    },
    // 点击查看详情
    openDetailModal(item) {
      const data = {
        platform: this.currentTab,
        params: {
          start_time: this.form.start_time,
          end_time: this.form.end_time,
          dealer_id: item.dealer_id,
        },
      };
      if (this.listTab == "2") {
        // 车系
        this.$refs["PieModal"].openModal(data);
      } else if (this.listTab == "3") {
        // 购车地
        this.$refs["LineModal"].openModal(data);
      }
    },
    exportExcel() {
      const params = this.form;
      let url = "";
      if (this.listTab == "1") {
        url = "/jjt/cluesManagement/flux/export";
      } else if (this.listTab == "2") {
        url = "/jjt/cluesManagement/series/export";
      } else if (this.listTab == "3") {
        url = "/jjt/cluesManagement/areaPreference/export";
      }
      exportTable(this.$http, params, this.currentTab, url, "filename");
    },
  },
  created() {
    this.setTableColumns();
    this.getSortGroup();
    this.getRegionList();
  },
  mounted() {
    this.$refs.dateTab.changeType("month");
    // this.getTableData(true);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_handle.scss";

::v-deep .ant-divider.ant-divider-vertical {
  background: rgba(255, 255, 255, 0.08);
  margin: 0 8px;
}
::v-deep .ant-table-body-inner {
  overflow-x: hidden;
}
::v-deep .ant-table-placeholder {
  background: #2b2f3b;
}

.font_white {
  @include font_color("font_color58");
}

.container {
  .tools {
    padding: 0 20px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .aft-search {
      width: 240px;
      height: 40px;
    }

    .button2 {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 113px;
      height: 40px;
      border-radius: 8px;
      line-height: 40px;
      font-size: 14px;
      border: none;
      @include font_color("font_color58");
      @include background_color("background_color2");
      .btn-icon {
        font-size: 18px;
        margin-right: 7px;
        @include font_color("font_color58");
      }
      &:hover .btn-icon {
        @include font_color("font_color58");
      }
    }
  }

  .sum-wrapper {
    width: calc(100% - 40px);
    border-radius: 8px;
    margin: 20px;
    height: 106px;
    background: #2b2f3b;
    display: flex;
    flex-direction: row;
    align-items: center;
    .sum-item {
      display: flex;
      padding: 32px;
      flex-direction: column;
      justify-items: center;
      align-items: flex-start;
      &.all {
        padding: 24px;
      }
      .sum-name {
        @include font_color("font_color59");
        font-size: 14px;
      }
      .sum-value {
        @include font_color("font_color58");
        font-size: 24px;
      }
    }
    .line {
      margin-left: 9px;
      margin-right: 8px;
      width: 1px;
      height: 51px;
      background: rgba(255, 255, 255, 0.08);
    }
  }

  .content {
    border-radius: 16px;
    margin: 0 20px;
    width: calc(100% - 40px);
    height: calc(100vh - 530px);
    overflow-y: hidden;
    .table {
      height: 100%;
      overflow-y: auto;
      .table-account {
        display: flex;
        flex-direction: row;
        margin: 16px 0;
        .table-account-left {
          display: flex;
          flex-direction: row;
          @include font_color("font_color3");
          > .img {
            margin: 0 8px;
            border-radius: 50%;
            @include border_color("border_color6");
          }
          .table-account-content {
            flex: 1;
            margin-left: 4px;
            .dealer-name {
              font-size: 14px;
              @include font_color("font_color58");
              line-height: 22px;
            }
            .store-info {
              line-height: 20px;
              font-size: 12px;
              margin: 2px 0;
            }
            .company-name {
              line-height: 20px;
              font-size: 12px;
              margin-bottom: 4px;
            }
            & > div {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }
        }
      }
      .action-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .action-text {
          @include font_color("font_color55");
          font-size: 14px;
          margin-left: 8px;
          cursor: pointer;
        }
      }

      .td-hover-number {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        &.fc26 {
          > span {
            @include font_color("font_color26");
          }
        }
        > span {
          font-size: 14px;
          font-weight: bold;
          @include font_color("font_color21");
        }
        > .bottom-title {
          font-size: 12px;
          @include font_color("font_color23");
          margin-top: 4px;
        }
      }
      .td-hover-box {
        padding: 30px 0;
      }
      .td-hover-box:hover {
        .table-show-icon {
          display: none;
        }
        .table-show-icon.active {
          display: inline-block;
        }
      }
    }
  }

  .page-div {
    text-align: right;
    padding: 20px;
  }
}
</style>
